.title-bar {
    height: 30px;
    cursor: move;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: #d2691e14;
}

.turn-highlighter {
    background: rgb(140 43 121 / 42%);
}

.title-bar-title {
    flex: 1 1;
    line-height: 1.5em;
    font-family: cursive;
    padding: 3px 0px;
    color: var(--text-dark);
    font-weight: bold;
}
.title-bar-min-btn,
.title-bar-expand-btn {
    border: none;
    cursor: pointer;
}
.title-bar-num-notifs {
    font-family: cursive;
    font-size: small;
    padding: 0 10px;
    background: var(--accent-dark);
    cursor: copy;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 10px;
    margin-left: -18px;
    margin-top: -35px;
    color: white;
    position: absolute;
}

.title-bar-btn {
    background-color: white;
    color: var(--text-dark);
    border: 1px solid var(--accent-dark);
    line-height: 20px;
    font-size: 13px;
    padding: 0 10px;
    border-radius: 5px;
    transition: 0.2s;
    margin: 0 3px;
    text-align: center;
    height: 20px;
}

.title-bar-btn:hover {
    cursor: pointer;
    background-color: var(--accent-dark);
    color: var(--text-light);
    border: 1px solid var(--accent-dark);
}

.hidden {
    display: none;
}
