.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: calc(100vh - 16px);
  width: calc(100vw - 16px); */
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(50px);
    transition: all 0.3s ease-in-out;
}

.inner-container {
    overflow: hidden;
    /*background: linear-gradient(#879d81, #c6d8c2);*/
    width: 100%;
    height: 100%;
    display: flex;
}

.inner-container p {
    font-size: 0.7em;
    font-weight: 300;
    color: var(--text-dark);
}

.chat-body {
    height: 100%;
    overflow-y: hidden;
    position: relative;
}
