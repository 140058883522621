.form-button {
    background: var(--see-through-level-4);
    color: var(--hover);
    margin: 20px 0;
    border: 1px solid var(--accent-dark);
    padding: 10px;
    text-align: center;
    height: 50px;
    min-width: 180px;
    font-family: "Karla", sans-serif !important;
    border-radius: 25px;
    box-sizing: border-box;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.form-button:hover {
    background-color: var(--accent-dark);
    color: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: 0vw 1vh 3vh -1vh rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0vw 1vh 3vh -1vh rgba(0, 0, 0, 0.3);
    box-shadow: 0vw 1vh 3vh -1vh rgba(0, 0, 0, 0.3);
}

.auth-form button:focus {
    outline: none;
}

.auth-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    max-width: 340px;
    min-width: 50%;
    left: 100%;
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.auth-form.on {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.auth-form span {
    padding: 3px;
    font-weight: bold;
    cursor: pointer;
}

.auth-form label {
    font-size: 75%;
    line-height: 20px;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
}

.auth-form:nth-child(1) {
    /*height: 350px;*/
}

.form-title {
    font-size: 1.7em;
    line-height: 100px;
    color: var(--text-dark);
    font-family: sans-serif;
}

.auth-form form {
    /*flex: 1;*/
    height: 60%;
}

/* .auth-form:nth-child(1) input[type="text"],
.auth-form:nth-child(1) input[type="password"] {
  width: 100%;
  margin: 10px 0;
} */

.auth-form:nth-child(1) input:focus {
    border: 2px solid rgba(255, 255, 255, 0.9);
}

.auth-form:nth-child(2) {
    /*height: 350px;*/
}

.auth-form:nth-child(2) #form-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.9);
}

/* .auth-form:nth-child(2) input[type="text"],
.auth-form:nth-child(2) input[type="password"] {
  width: 100%;
  margin: 10px 0;
} */

/*.auth-form:nth-child(2) input:focus {*/
/*    border: 1px solid rgba(255, 255, 255, 0.9);*/
/*}*/

/*.auth-form:nth-child(2) button {*/
/*    color: white;*/
/*    margin: 15px 0;*/
/*}*/

.avatar-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.avatar-skeleton,
.avatar-skeleton-selected {
    margin: 1px;
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
    box-sizing: border-box;
}

.avatar-skeleton {
    background: var(--see-through-level-2);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.avatar-skeleton:hover {
    background: var(--see-through-level-3);
}

.avatar-skeleton-selected {
    background: var(--see-through-level-5);
}

.avatars-picker {
    height: 115px;
    overflow: scroll;
    padding: 10px;
    background: var(--see-through-level-2);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.avatars-picker::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.avatars-picker {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.top-logo-container {
    display: flex;
    justify-content: center;
}

.top-logo-container img {
    width: 140px;
    height: auto;
}
