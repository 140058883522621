.enlarged-vertical-stack-card {
    height: 200px;
    margin-top: -175px;
}

.enlarged-cash-card {
    margin-top: -198px;
}

.enlarged-card-image {
    height: 200px !important;
}

.enlarged-horizontal-stack-card {
    height: 200px;
    margin-left: -90px;
}

.card-selected {
    border-radius: 4px;
    outline-style: outset;
    outline-color: #ff0000;
    outline-width: 2px;
}

.card-zoomed {
    height: 200px;
}

.card-inverted {
    transform: rotate(180deg);
}
