.panel {
    display: inline-block;
    position: relative;
    height: 100%;
}

.panel:nth-child(1) {
    float: left;
    width: 40%;
}

.panel:nth-child(2) {
    float: right;
    width: 60%;
}
