/* .room-info-outer-container {
    flex: 1;
    width: 100%;
} */

.room-info-inner-container {
    text-align: left;
    /* padding-top: 20px; */
    /* padding: 10px 35px 10px 10px;
    min-width: 300px; */
}

.room-info-inner-container p {
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: var(--text-dark);
}

.room-info-inner-container button {
    cursor: pointer;
    border: none;
    padding: 8px;
    width: 128px;
    background-color: rgba(255, 255, 255, 0.03);
    color: rgba(255, 255, 255, 0.8);
    margin: 25px 5px;
    outline: white;
}

.highlighted-text {
    font-size: 125%;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.9);
}

.click-to-copy-container {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--accent-dark);
    padding: 3px 20px;
    border-radius: 5px;
    margin: 10px 0;
}

.click-to-copy-container input {
    width: 5px;
    border: none;
    outline: none;
    background: transparent;
    color: transparent;
}

.click-to-copy-container p {
    color: var(--text);
    font-weight: 600;
    padding: 5px;
}

.click-to-copy-container img {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin-top: 5px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    color: rgba(255, 255, 255, 0.3);
    background-color: black;
    font-size: 50%;
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    cursor: pointer;
    visibility: visible;
}
