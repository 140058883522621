.card-picker .MuiPaper-root {
    display: flex;
    flex-direction: column !important;
    padding: 10px;
}

.card-picker .MuiDialogActions-root {
    display: flex;
    flex-direction: column;
    /*justify-content: space-evenly !important;*/
    min-width: min-content;
    max-width: 100% !important;
    padding: 20px 0 !important;
}

.MuiDialogContent-root {
    min-width: fit-content;
    padding: 10px !important;
}

.MuiDialogTitle-root,
.MuiDialogContentText-root {
    text-align: center;
}

.dialog-btn {
    margin: 5px;
    max-width: 155px !important;
    min-width: max-content;
    width: 160px;
    background-color: white;
    color: var(--text-dark);
    border: 1px solid var(--accent-dark);
    line-height: 30px;
    font-size: 16px;
    padding: 0 10px;
    border-radius: 5px;
    transition: 0.2s;
    text-align: center;
    height: 30px;
}

.dialog-btn:hover {
    cursor: pointer;
    background-color: var(--accent-dark);
    color: var(--text-light);
    border: 1px solid var(--accent-dark);
}

.MuiDialogActions-root p {
    font-size: small !important;
}

.action-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: -webkit-fill-available;
}

.property-sets {
    display: flex;
    flex-direction: row;
}

#alert-dialog-payload {
    font-weight: 900;
}
