.stacks-container {
    /*overflow-y: scroll;*/
    padding: 0 10px;
    margin-top: 10px;
}
.maximised-window {
    /*position: fixed;*/
    /*max-width: 100%;*/
    /*transform: unset;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    /*height: 100%;*/
    z-index: 1000;
}
.maximised-window > .stacks-container {
    height: calc(100% - 50px);
}
