.home-image-side {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.home-image-overlay {
    background: linear-gradient(288deg, #2c3c38, #150c32);
    z-index: 3;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.9;
    transition: all 0.3s ease-in-out;
}

.home-welcome-text-container {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex-direction: column;
    color: white;
    font-size: 36px;
    width: 100%;
    height: 100%;
    z-index: 11;
    position: absolute;
    padding: 50px;
    font-family: cursive;
    background: linear-gradient(transparent, #0000008c);
}

.home-disclaimer-message {
    font-size: 15px;
    color: #aabd5f;
    opacity: 0.7;
}

.logo-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.logo-container img {
    /*width: calc(100% - 48px);*/
    height: 100%;
}
