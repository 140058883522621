.auth-form textarea,
.auth-form input {
    border: 1px solid #b9bbbb;
    background-image: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: var(--text-dark);
    padding: 12px;
    box-sizing: border-box;
    font-family: "Karla", sans-serif !important;
    border-radius: 25px;
}

.auth-form textarea:focus,
.auth-form input:focus {
    border: 1px solid rgba(54, 64, 62, 0.83);
    outline: None;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.form-input-container span {
    font-size: 12px;
    color: red;
    font-weight: 100;
}
