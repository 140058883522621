/* .user:hover {
  background: rgba(10, 10, 10, 0.3);
  color: #1d262d;
  cursor: default;
} */

.user-detail {
    display: flex;
    width: fit-content;
    font-weight: 200;
    margin: 5px 0;
}

.user-detail.away {
    opacity: 0.3;
}

.user-detail img {
    width: 30px;
    height: 30px;
}

.user-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    padding: 5px;
    margin-right: 20px;
    border-radius: 40px;
    background-color: white;
}

.user-name {
    color: var(--text);
    font-size: 1.2em;
    line-height: 30px;
    padding: 5px;
}

.user-name span {
    padding: 0;
    font-weight: 100;
    font-size: 0.85em;
    cursor: default;
}
