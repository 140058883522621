* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Nunito", sans-serif;
    scrollbar-color: var(--highlight) transparent;
    scrollbar-width: thin;
}

:root {
    /* rgba(15, 15, 15, 0.9);;*/
    --main: rgb(255, 255, 255);
    --base: rgb(25 37 53 / 58%);
    --highlight: rgb(107, 58, 126);
    --highlight-hover: rgb(129, 163, 196);
    --text: whitesmoke;
    --text-light: whitesmoke;
    --text-dark: rgb(76, 86, 86);
    --text-soft: rgb(138, 145, 201);
    --shadow: rgba(0, 0, 0, 0.5);
    --shade-1: rgba(58, 73, 93, 0.4);
    --shade-2: rgb(65, 140, 179);
    --see-through-level-1: rgba(18, 21, 35, 0.5);
    --see-through-level-2: rgba(33, 40, 49, 0.6);
    --see-through-level-3: rgba(255, 255, 255, 0.4);
    --see-through-level-4: rgba(255, 255, 255, 0.7);
    --see-through-level-5: rgba(255, 255, 255, 0.9);
    /*--self-message: rgba(134, 167, 187, 0.6);*/
    --self-message: rgba(15, 15, 25, 0.6);
    --message: rgba(255, 255, 255, 0.85);
    --active: rgba(23, 190, 187, 0.9);
    --passive: rgba(23, 190, 187, 0.1);
    --selection: rgba(40, 180, 240, 0.5);
    --hover: rgba(40, 40, 40, 0.8);
    --accent-dark: rgb(19 26 25);
}
