/* The container for checkboxes */
.form-options {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-top: 10px;
    margin-left: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.form-options input {
    display: none;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0vw 1vh 3vh -1vh rgb(0 0 0 / 30%);
    cursor: pointer;
}

/* On mouse-over, add a grey background color */
.form-options:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.form-options input:checked ~ .checkmark {
    background-color: rgba(255, 255, 255, 0.05);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.form-options input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.form-options .checkmark:after {
    left: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
