.auth-switch {
    background: var(--see-through-level-4);
    border: 1px solid rgb(19 26 25);
    color: var(--hover);
    font-size: 24px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    line-height: 30px;
    height: 50px;
    min-width: 150px;
    display: block;
    z-index: 99;
    position: absolute;
    box-sizing: border-box;
    border-radius: 50px;
    /* color: white; */
    font-weight: bold;
    top: 30px;
    right: -62px;
    transition: all 0.1s ease-in-out;
}

.auth-switch:hover {
    background-color: var(--accent-dark);
    color: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: 0vw 1vh 3vh -1vh rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0vw 1vh 3vh -1vh rgba(0, 0, 0, 0.3);
    box-shadow: 0vw 1vh 3vh -1vh rgba(0, 0, 0, 0.3);
}
