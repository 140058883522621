.game-container {
    width: 100%;
    height: 100%;
    background-color: white;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.window {
    position: relative;
    margin: 20px 10px;
    /* display: flex;
  flex-direction: column; */
    width: -moz-fit-content;
    width: fit-content;
    height: fit-content;
    max-width: 60%;
    box-shadow: 2px 2px 6px 0px rgb(0 0 0 / 15%);
    /*transition: transform 0.5s;*/
    /* margin: 10px; */
    background-color: white;
    z-index: 1;
    border-radius: 0.2em;
}
.stacks-container {
    /* position: relative; */
    display: flex;
    /* flex-direction: row; */
    /* width: -moz-fit-content;
  width: fit-content;
  height: fit-content; */
    /* box-shadow: 2px 2px 6px 0px rgb(0 0 0 / 15%);
  transition: transform 0.5s;
  margin: 10px;
  padding: 10px;
  background-color: white;
  z-index: 1;
  border-radius: 0.7em; */
    flex-wrap: wrap;
}
.bring-to-front:hover {
    transform: scale(1.2);
}
.vertical-stacks-container {
    display: flex;
    flex-direction: row;
}
.vertical-stacks-container,
.horizontal-stacks-container {
    padding-top: 5px;
}
.horizontal-stacks-container {
    display: flex;
    justify-content: space-around;
}
.parent-vertical-stack {
    display: flex;
    flex-direction: column;
    margin: 2px;
    padding-top: 108px; /* -ve of .child-vertical-stack margin-top */
    padding-bottom: 15px;
    /* padding-bottom: -95px; */
}

.parent-vertical-stack:hover {
    padding-bottom: 0;
}

.parent-cash-stack {
    padding-top: 122px; /* -ve of .child-cash-stack margin-top */
}
.parent-horizontal-stack {
    height: fit-content;
    padding: 5px;
    background: #9facbf42;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding-left: 50px; /* -ve of .child-horizontal-stack margin-left */
    border: 1px solid var(--accent-dark);
    border-radius: 4px;
}
.child-vertical-stack {
    margin-top: -108px; /* -ve of .parent-vertical-stack padding-top */
}
.child-horizontal-stack {
    margin-left: -45px; /* -ve of .parent-horizontal-stack padding-left */
}
.child-vertical-stack:hover {
    margin-bottom: 15px;
}
.child-cash-stack {
    margin-top: -122px; /* -ve of .parent-cash-stack padding-top */
}
/* .child-horizontal-stack:hover {
    margin-right: 35px;
    z-index: 1000;
} */
.card {
    position: relative;
    height: 120px;
    /*transition: 0.5s;*/
    transition-timing-function: ease-in-out;
}

.hand-card-hover {
    transition: 0.2s;
}

.hand-card-hover:hover {
    transform: scale(1.2);
}
.stacks-hidden {
    margin: 0 !important;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}
.special-container {
    position: fixed;
    max-width: 80%;
    width: max-content;
    left: 50%;
    bottom: 0;
    margin-bottom: 0;
    transform: translate(-50%, -10px);
    z-index: 999;
}
.special-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;
}
.special-container > .stacks-container-header {
    cursor: auto;
}

.enlarged-2_0 {
    transform: scale(2);
}

.card-container {
    height: 100px;
    margin-top: -95px;
}
