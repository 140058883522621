.enlarged-parent-vertical-stack {
    padding-top: 176px;
}

.enlarged-cash-stack {
    padding-top: 198px;
}

.enlarged-parent-horizontal-stack {
    padding-left: 90px;
}

.outlined-stack {
    outline: dotted;
}
